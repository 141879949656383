export enum FILE_TYPE {
  LINK = 'link',
  VIDEO = 'video',
  IMAGE = 'image',
  AWARD = 'award',
}

export enum FILE_TYPE_RESPONSE {
  // _SV is response value from server
  YOUTUBE_SV = 'YOUTUBE',
  VIDEO_SV = 'VIDEO',
  IMAGE_SV = 'IMAGE'
}

export enum COUNTRY_EXPOSURE {
  ALL = 'all',
  SELECT = 'select'
}

export enum DEVICE_TYPE {
  PC = 'PC',
  MOBILE = 'MOBILE'
}

export enum START_DATE_OPTS {
  IMMEDIATE = 'immediate',
  RESERVE = 'reserve'
}

export enum PRODUCT_PAGE_RESOURCES {
  BACKGROUND = 'BACKGROUND',
  TRAILER = 'TRAILER',
  INTRODUCE_SCREENSHOT = 'INTRODUCE_SCREENSHOT',
  COVER_TITLE = 'COVER_TITLE',
  COVER_TITLE_LIST_DEFAULT = 'COVER_TITLE_LIST_DEFAULT',
  COVER_TITLE_BACKGROUND = 'COVER_TITLE_BACKGROUND',
  INTRODUCE_ONE_LINE = 'INTRODUCE_ONE_LINE',
  AWARD = 'AWARD'
}

export enum PRODUCT_PAGE_RESOURCES_RESPONSE {
  BACKGROUND = 'BACKGROUND',
  TRAILER = 'TRAILER',
  INTRODUCE_SCREENSHOT = 'INTRODUCESCREENSHOT',
  COVER_TITLE = 'COVERTITLE',
  COVER_TITLE_LIST_DEFAULT = 'COVERTITLELISTDEFAULT',
  COVER_TITLE_BACKGROUND = 'COVERTITLEBACKGROUND',
  INTRODUCE_ONE_LINE = 'INTRODUCEONELINE',
  AWARD = 'AWARD'
}

export enum PROGRESS_STATUS {
  NONE = 'NONE',
  REJECT = 'REJECT',
  REVIEW = 'REVIEW',
  READY = 'READY',
  LIVE = 'LIVE',
  END = 'END',
  SUSPEND = 'SUSPEND'
}

export enum PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE {
  CUSTOM = 'CUSTOM'
}

export enum SettingLabels {
  OS = 'OS',
  PROCESSOR = 'Processor',
  MEMORY = 'Memory',
  GRAPHIC = 'Graphic',
  DIRECTX = 'DirectX',
  STORAGE = 'Storage',
  SOUND = 'Sound'
}

export enum PROJECT_PRODUCT_SEARCH_TYPE {
  PROJECT_PRODUCT_NAME = 'PROJECT_NAME',
  PROJECT_NAME = 'PROJECT_NAME',
  PRODUCT_NAME = 'PRODUCT_NAME'
}
