import { SCREEN_TYPES } from '@/constants/product-page.const';
import { COUNTRY_EXPOSURE, PROGRESS_STATUS } from '@/enums/product-page.enum';
import type { ImageLanguageType } from '@/types/common/file.type';
import type {
  ProductLinkType,
  ProductPageFormType,
  ProductPageLanguage,
  ProductSettingType
} from '@/types/product-page.type';

export const isAnimatedWebP = (src: string): Promise<boolean> => {
  return new Promise((resolve: (value: boolean | PromiseLike<boolean>) => void) => {
    const request = new XMLHttpRequest();
    request.open('GET', src, true);
    request.addEventListener('load', function() {
      if (request.response.includes('ANMF')) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
    request.send();
  });
};

export const isCorrectResolution = (
  width: number,
  height: number,
  reqWidth: number,
  reqHeight: number
): boolean => {
  const requireWidth = Number(reqWidth ?? 0);
  const requireHeight = Number(reqHeight ?? 0);

  if (width !== requireWidth || height !== requireHeight) {
    return false;
  }

  return true;
};

export const hasInputAllRequiredFields = (
  values: ProductPageFormType,
  isCollection?: boolean,
  currentLang?: string,
  isForValidate?: boolean
) => {
  const selectedLang = Object.entries(values.mappedSorted ?? []).find(
    (img: any) => img[0] === currentLang
  );
  if (values?.exhibitionVideosAndImages.length > 0) {
    if (
      values?.exhibitionVideosAndImages
        .filter((f: ImageLanguageType) => f.lang === currentLang)
        .some((image: ImageLanguageType) => image.type === 'link' && !image.movieUrl)
    ) {
      return false;
    }
  }

  if (values.exposureCountry === COUNTRY_EXPOSURE.SELECT) {
    if (values.countryBySearch?.length === 0) {
      return false;
    }
  }

  if (values.screenType === SCREEN_TYPES.VIDEO) {
    if (values.productPageBgVideo?.length === 0) {
      return false;
    }

    if (!values.productPageBgVideo[0].fileId) {
      return false;
    }
  }

  if (!isCollection) {
    if (
      !values?.exposureCountry ||
      !values?.screenType ||
      (selectedLang && Array.isArray(selectedLang[1]) && selectedLang[1].length === 0) ||
      values?.image1x1.every((image: ImageLanguageType) => !image.fileId) ||
      values?.image16x9.every((image: ImageLanguageType) => !image.fileId) ||
      !values?.oneLineIntroduction ||
      values?.settings.some(
        (setting: ProductSettingType) => !setting.minimumRequirement && setting.isRequired
      ) ||
      values?.supportedLanguages.length === 0
    ) {
      return false;
    }

    if (values.exposureCountry === COUNTRY_EXPOSURE.SELECT) {
      if (!values.countryBySearch) {
        return false;
      }
    }

    return true;
  } else {
    if (
      !values?.exposureCountry ||
      !values?.screenType ||
      (selectedLang && Array.isArray(selectedLang[1]) && selectedLang[1].length === 0) ||
      values?.image1x1.every((image: ImageLanguageType) => !image.fileId) ||
      values?.image16x9.every((image: ImageLanguageType) => !image.fileId) ||
      !values?.oneLineIntroduction ||
      values?.supportedLanguages.length === 0
    ) {
      return false;
    }

    if (values.exposureCountry === COUNTRY_EXPOSURE.SELECT) {
      if (!values.countryBySearch) {
        return false;
      }
    }

    const existedImage1x1Lang = values.image1x1.find((image: ImageLanguageType) => {
      return image.lang === currentLang;
    })?.fileId;
    const existedImage16x9Lang = values.image16x9.find((image: ImageLanguageType) => {
      return image.lang === currentLang;
    })?.fileId;

    if (!existedImage1x1Lang || !existedImage16x9Lang) {
      return false;
    }

    if (isForValidate) {
      const isMissingTranslate = values.translatedContents?.some(
        (c: ProductPageLanguage<ProductLinkType[]>) => c.oneLineIntroduction === ''
      );
      if (isMissingTranslate) {
        return false;
      }
    }

    return true;
  }
};

export const getStatusLabel = (status: PROGRESS_STATUS) => {
  switch (status) {
    case PROGRESS_STATUS.NONE:
      return '-';
    case PROGRESS_STATUS.REJECT:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_declined_msg';
    case PROGRESS_STATUS.LIVE:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_active_msg';
    case PROGRESS_STATUS.END:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_expired_msg';
    case PROGRESS_STATUS.SUSPEND:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_suspend_msg';
    case PROGRESS_STATUS.READY:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_upcoming_msg';
    case PROGRESS_STATUS.REVIEW:
      return 'studio.prj_prod.this_proj.this_prod.list_this_status_in_review_msg';
  }
};
